import { Box } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { HubspotProvider } from '@aaronhayes/react-use-hubspot-form';

const ContactLayout = ({ children }: { children: ReactNode }) => (
  <HubspotProvider>
    <Box
      as="main"
      width="100%"
      height="100vh"
      overflow="auto"
      background="linear-gradient(0deg, #eff2fa, #faf1ef)"
    >
      {children}
    </Box>
  </HubspotProvider>
);

export default ContactLayout;
