import { extendTheme } from '@chakra-ui/react';
import styles from 'theme/foundations/styles';
import breakpoints from 'theme/foundations/breakpoints';
import colors from 'theme/foundations/colors';
import components from 'theme/components';
import textStyles from 'theme/foundations/textStyles';
import layerStyles from './foundations/layerStyles';
import shadows from './foundations/shadows';

const overrides = {
  styles,
  layerStyles,
  breakpoints,
  shadows,
  colors,
  textStyles,
  components,
  fonts: {
    heading: "'Noto Sans JP', sans-serif",
    body: "'Noto Sans JP', sans-serif",
  },
};
// @ts-ignore
export default extendTheme(overrides);
