/* eslint-disable import/no-cycle */
// TODO: import/no-cycleはまずそうなのでどこかで直す
import { Store, combineReducers } from 'redux';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import logger from 'redux-logger';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { ModalReducer, initModalState } from 'ducks/modal';
import { SessionReducer, initSessionState } from 'ducks/session';
import { initQuotationState, QuotationReducer } from 'ducks/quotation';

const rootReducer = combineReducers({
  session: SessionReducer,
  modal: ModalReducer,
  quotation: QuotationReducer,
});

const preloadedState = () => ({
  session: initSessionState,
  modal: initModalState,
  quotation: initQuotationState,
});

export type StoreState = ReturnType<typeof preloadedState>;

export type ReduxStore = Store<StoreState>;

const createStore = () => {
  const isDevelopment = process.env.NODE_ENV !== 'production';
  const middleWareList = [...getDefaultMiddleware()];
  if (isDevelopment) {
    middleWareList.push(logger);
  }

  return configureStore({
    reducer: rootReducer,
    middleware: middleWareList,
    devTools: isDevelopment,
    preloadedState: preloadedState(),
  });
};

export const useAppSelector: TypedUseSelectorHook<StoreState> = useSelector;

export default createStore();
