import React, { FC, ReactNode } from 'react';
import { Box } from '@chakra-ui/react';

type Props = {
  children?: ReactNode;
};

const PlainLayout: FC<Props> = ({ children }) => (
  <Box
    as="main"
    width={{
      base: '100%',
      md: '100%',
      lg: '37rem',
    }}
    margin="0 auto"
    padding={{
      base: 'initial',
      md: 'initial',
      lg: '3rem',
    }}
  >
    {children}
  </Box>
);

export default PlainLayout;
