import { FC } from 'react';
import { ToastContainer, cssTransition, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ClassNames } from '@emotion/react';
import colors from 'theme/foundations/colors';

const Zoom = cssTransition({
  enter: 'zoomIn',
  exit: 'zoomOut',
  duration: 400,
});
export { toast };

export const Toast: FC = () => (
  <ClassNames>
    {({ cx, css }) => (
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        closeButton={false}
        transition={Zoom}
        className={cx(css`
          .Toastify__toast {
            min-width: 12.125rem;
            height: 4.5rem;
            border-radius: 8px;
            background: ${colors.textGray['87']};
            text-align: center;
            font-size: 1rem;
            color: ${colors.white['100']};
          }
          @keyframes zoomIn {
            from {
              opacity: 0;
              transform: scale3d(0.3, 0.3, 0.3);
            }

            50% {
              opacity: 1;
            }
          }

          .zoomIn {
            animation-name: zoomIn;
          }

          @keyframes zoomOut {
            from {
              opacity: 1;
            }

            50% {
              opacity: 0;
              transform: scale3d(0.3, 0.3, 0.3);
            }

            to {
              opacity: 0;
            }
          }

          .zoomOut {
            animation-name: zoomOut;
          }
        `)}
      />
    )}
  </ClassNames>
);
