import { ReactNode, useState } from 'react';
import { ResetPasswordEmailContext } from 'hooks/context';

const ContextProvider = ({ children }: { children: ReactNode }) => {
  const [resetPasswordEmail, setResetPasswordEmail] = useState<string>('');

  return (
    <ResetPasswordEmailContext.Provider value={{ resetPasswordEmail, setResetPasswordEmail }}>
      {children}
    </ResetPasswordEmailContext.Provider>
  );
};

export default ContextProvider;
