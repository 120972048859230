const Link = {
  baseStyle: {
    // textDecoration: "none !important",
    _focus: {
      boxShadow: 'unset',
      textDecoration: 'none',
    },
    _active: {
      textDecoration: 'none',
    },
  },
};

export default Link;
