export const ModalTypes = {
  SIGN_IN: 'signin',
  SIGN_UP: 'signup',
  SIGN_UP_CONFIRM: 'signupConfirm',
  PACKAGE_DETAIL_SIGN_IN: 'packageDetailSignin',
  PACKAGE_DETAIL_SIGN_UP: 'packageDetailSignup',
  PASSWORD_RESET_REQUEST: 'passwordResetRequest',
  PASSWORD_RESET_REQUEST_CONFIRM: 'passwordResetRequestConfirm',
  CLOSE: 'close',
} as const;

export type ModalType = typeof ModalTypes[keyof typeof ModalTypes];

export interface ModalState {
  modalType: ModalType;
}
