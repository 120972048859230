import { ThemeComponentProps } from '@chakra-ui/theme';

const Button = {
  defaultProps: {
    variant: 'solid',
    size: 'md',
    colorScheme: 'primary',
  },
  baseStyle: {
    borderRadius: 8,
    fontWeight: '700',
    fontSize: {
      sm: '0.875rem',
      md: '0.875rem',
      lg: '1rem',
      xl: '1rem',
    },
    _focus: {
      boxShadow: 'unset',
    },
  },
  variants: {
    solid: (props: ThemeComponentProps) => ({
      boxShadow: 'default',
      _hover: {
        backgroundColor: `${props.colorScheme}.400`,
        boxShadow: 'hover',
      },
      _focus: {
        outline: 0,
        boxShadow: 'focus',
      },
      _disabled: {
        opacity: 1,
        color: 'white.100',
        backgroundColor: 'textGray.12',
        pointerEvents: 'none',
        cursor: 'not-allowed',
      },
    }),
    selector: (props: ThemeComponentProps) => ({
      padding: '1.375rem 2rem',
      backgroundColor: 'textGray.4',
      _hover: {
        backgroundColor: `${props.colorScheme}.200`,
      },
      _focus: {
        backgroundColor: `${props.colorScheme}.200`,
        boxShadow: 'focus',
      },
      _disabled: {
        opacity: 1,
        color: 'white.100',
        backgroundColor: 'gray.200',
        pointerEvents: 'none',
        cursor: 'not-allowed',
      },
    }),
    quantity: (props: ThemeComponentProps) => ({
      padding: '1rem',
      backgroundColor: 'textGray.4',
      borderStyle: 'solid',
      borderWidth: '1px',
      _hover: {
        backgroundColor: `${props.colorScheme}.200`,
      },
      _focus: {
        backgroundColor: `${props.colorScheme}.200`,
      },
      _disabled: {
        opacity: '1.0',
        pointerEvents: 'none',
        cursor: 'not-allowed',
      },
    }),
    outline: (props: ThemeComponentProps) => ({
      background: 'white.100',
      borderStyle: 'solid',
      borderColor: `${props.colorScheme}.600`,
      color: `${props.colorScheme}.600`,
      _hover: {
        backgroundColor: `${props.colorScheme}.200`,
      },
    }),
    icon: () => ({
      background: 'none',
      shadow: 'none',
      color: 'primary.85',
    }),
    ghost: {},
    link: (props: ThemeComponentProps) => ({
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: '1.8125rem',
      letterSpacing: '0.2px',
      borderRadius: 0,
      paddingBottom: '0.1rem',
      borderBottomWidth: '1px',
      borderBottomStyle: 'solid',
      borderBottomColor: 'rgba(0, 0, 0, 0)',
      minWidth: 0,
      _active: {
        textDecoration: 'none',
        color: `${props.colorScheme}.500`,
        borderBottomColor: `${props.colorScheme}.500`,
        borderBottomWidth: '1px',
        borderBottomStyle: 'solid',
      },
      _hover: {
        textDecoration: 'none',
        color: `${props.colorScheme}.500`,
        borderBottomColor: `${props.colorScheme}.500`,
        borderBottomWidth: '1px',
        borderBottomStyle: 'solid',
      },
    }),
  },
  sizes: {
    sm: {
      fontSize: { base: '0.875rem', md: '0.875rem', lg: '1rem' },
      minWidth: 128,
      height: '3.5rem',
    },
    md: {
      fontSize: { base: '0.875rem', md: '0.875rem', lg: '1rem' },
      minWidth: 320,
      height: '4.5rem',
    },
    lg: {
      minWidth: 320,
      fontSize: { base: '0.875rem', md: '0.875rem', lg: '1rem' },
      height: '4.5rem',
    },
  },
};

export default Button;
