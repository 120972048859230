const layerStyles = {
  flexItem: {
    justifySelf: { base: 'center', md: 'center', lg: 'flex-start' },
    justifyContent: { base: 'center', md: 'center', lg: 'flex-start' },
    alignItems: { base: 'center', md: 'center', lg: 'flex-start' },
  },
  border: {
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'textGray.12',
    borderRadius: '8px',
  },
};

export default layerStyles;
