import React from 'react';
import DefaultHeaderLogo from 'layouts/DefaultHeaderLogo';
import { Box, Flex } from '@chakra-ui/react';

const ContentFormHeader = () => {
  return (
    <Box
      as="header"
      display="flex"
      alignItems="center"
      position="sticky"
      top={0}
      height={{ base: '3.5rem', md: '3.5rem', lg: '4.5rem' }}
      padding="0 1.5rem"
      backgroundColor="transparent"
      zIndex={1}
      bgColor="white.100"
    >
      <Flex align="center" justify="space-between" width="100%" maxWidth="64rem" margin="0 auto">
        <DefaultHeaderLogo />
      </Flex>
    </Box>
  );
};

export default ContentFormHeader;
