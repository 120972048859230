import { useAppSelector } from '../createStore';

export const useUserEmailVerified = () =>
  useAppSelector((state) => ({
    emailVerified: state.session.emailVerified || state.session.currentUser?.email_verified,
  }));

export const useUserId = () => useAppSelector((state) => ({ userId: state.session.userId }));

export const useUserEmail = () =>
  useAppSelector((state) => ({ userEmail: state.session.currentUser?.email }));
