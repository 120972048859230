import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { QuotationState } from './types';

export const initialState: QuotationState = {
  quotationPackages: [],
};

const quotationSlice = createSlice({
  name: 'quotation',
  initialState,
  reducers: {
    setQuotationPackageQuantity: (
      state,
      action: PayloadAction<{ id: number; quantity?: number; optionName?: string }>,
    ) => {
      const {
        payload: { id, quantity, optionName },
      } = action;
      const index = state.quotationPackages.findIndex((p) => p.id === id);
      if (index < 0) {
        return state;
      }
      state.quotationPackages[index].inputQuantity = quantity;
      state.quotationPackages[index].optionName = optionName;
      state.quotationPackages[index].isError = !quantity;
      return state;
    },
    validateQuotationPackages: (state) => {
      state.quotationPackages.forEach((p) => {
        if (!p.inputQuantity) {
          p.isError = true;
        }
      });
      return state;
    },
  },
});

export default quotationSlice;
export const QuotationReducer = quotationSlice.reducer;
