import React, { FC } from 'react';
import Link from 'next/link';
import { paths } from 'constants/paths';
import { sendClickLog, LogCategories } from 'libs/gtag';
import { Button, Flex, LinkBox, LinkOverlay, Text } from '@chakra-ui/react';
import { blogs } from 'constants/links';
import { ChevronRightIcon } from '@chakra-ui/icons';

const menuItems = [
  {
    path: paths.quotation,
    label: '資材一覧',
  },
  {
    path: paths.blog,
    label: 'お客さまの声',
  },
  {
    path: blogs.sustainable,
    label: 'サステナブルな取り組み',
  },
];

type Props = {
  closeCallback?: () => void;
};

const NavigationMenu: FC<Props> = ({ closeCallback }) => {
  const handleClick = (label: string) => {
    sendClickLog({
      category: LogCategories.HEADER,
      label,
    });
    if (closeCallback) closeCallback();
  };

  return (
    <Flex
      as="nav"
      display="flex"
      justify={{ base: 'start', lg: 'space-between' }}
      direction={{ base: 'column', lg: 'row' }}
      alignItems={{ base: 'start', lg: 'center' }}
      gap={{ base: '1.5rem', lg: 0 }}
      width={{ base: '100%', lg: 'auto' }}
      pl={{ base: '0', lg: '2rem' }}
    >
      <Flex
        display="flex"
        alignItems={{ base: 'start', lg: 'center' }}
        padding={0}
        margin={0}
        gap={{ base: '0', lg: '1.5rem' }}
        direction={{ base: 'column', lg: 'row' }}
        width={{ base: '100%', lg: 'auto' }}
      >
        {menuItems.map((menu) => (
          <LinkBox
            key={menu.path}
            w={{ base: '100%', lg: 'auto' }}
            _hover={{
              color: 'primary.600',
            }}
            onClick={() => handleClick(menu.label)}
          >
            <Link href={menu.path} passHref>
              <LinkOverlay
                as="span"
                cursor="pointer"
                display="flex"
                justifyContent="space-between"
                py={{ base: '0.75rem', lg: '0' }}
                target={menu.path === blogs.sustainable ? '_blank' : '_self'}
                rel={menu.path === blogs.sustainable ? 'noopener noreferrer' : ''}
              >
                <Text textStyle="body2" color="inherit" fontSize={{ base: '14px', lg: '16px' }}>
                  {menu.label}
                </Text>
                <ChevronRightIcon w="1.5rem" h="1.5rem" display={{ base: 'block', lg: 'none' }} />
              </LinkOverlay>
            </Link>
          </LinkBox>
        ))}
      </Flex>
      <Flex
        display="inline-flex"
        gap={4}
        direction={{ base: 'column', lg: 'row' }}
        width={{ base: '100%', lg: '340px' }}
      >
        <LinkBox
          onClick={() => {
            sendClickLog({
              category: LogCategories.HEADER,
              label: 'お問い合わせ',
            });
          }}
        >
          <Link href={paths.contact} passHref>
            <Button
              size="xs"
              variant="outline"
              px="1.5rem"
              py="1rem"
              height={{ base: '40px', lg: '48px' }}
              width="100%"
              boxSizing="border-box"
            >
              <Text fontSize={{ base: '14px', lg: '1rem' }} lineHeight={1}>
                お問い合わせ
              </Text>
            </Button>
          </Link>
        </LinkBox>
        <LinkBox
          onClick={() => {
            sendClickLog({
              category: LogCategories.HEADER,
              label: '資料ダウンロード',
            });
          }}
        >
          <Link href={paths.inquiryServiceDocument} passHref>
            <Button
              size="xs"
              px="1.5rem"
              py="1rem"
              height={{ base: '40px', lg: '48px' }}
              width="100%"
            >
              <Text fontSize={{ base: '14px', lg: '1rem' }} lineHeight={1}>
                資料ダウンロード
              </Text>
            </Button>
          </Link>
        </LinkBox>
      </Flex>
    </Flex>
  );
};

export default NavigationMenu;
