import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SessionState } from './types';

export const initialState: SessionState = {
  currentUser: undefined,
  emailVerified: false,
  idToken: undefined,
  userId: undefined,
};

const SessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    setSession: (state, action: PayloadAction<SessionState>) => ({
      ...state,
      ...action.payload,
    }),
    unsetSession: (state) => ({
      ...state,
      ...initialState,
    }),
  },
});

export default SessionSlice;
export const SessionReducer = SessionSlice.reducer;
