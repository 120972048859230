import { createContext } from 'react';

interface ResetPasswordEmailContextType {
  resetPasswordEmail: string;
  setResetPasswordEmail: (email: string) => void;
}

export const ResetPasswordEmailContext = createContext<ResetPasswordEmailContextType>({
  resetPasswordEmail: '',
  setResetPasswordEmail: () => {},
});
