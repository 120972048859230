import { FC } from 'react';
import { useRouter } from 'next/router';
import ShizaiLogo from 'public/images/shizai_logo.svg';
import Link from 'next/link';
import { sendClickLog, LogCategories } from 'libs/gtag';
import { paths } from 'constants/paths';
import { Box, AspectRatio } from '@chakra-ui/react';
import Image from 'next/legacy/image';

const DefaultHeaderLogo: FC = () => {
  const router = useRouter();
  // NOTE: SEOを考慮してTOPページのみh1とする
  if (router.pathname === '/') {
    return (
      <Link href={paths.root} passHref>
        <Box
          as="h1"
          display="flex"
          alignItems="center"
          onClick={() => {
            sendClickLog({
              category: LogCategories.HEADER,
              label: 'shizaiのロゴ',
            });
          }}
        >
          <AspectRatio
            cursor="pointer"
            ratio={3.2}
            width={{
              base: '58px',
              md: '58px',
              lg: '80px',
            }}
          >
            <Image src={ShizaiLogo} alt="shizaiのロゴ" layout="fill" />
          </AspectRatio>
        </Box>
      </Link>
    );
  }
  return (
    <Link href={paths.root} passHref>
      <Box
        display="flex"
        alignItems="center"
        onClick={() => {
          sendClickLog({
            category: LogCategories.HEADER,
            label: 'shizaiのロゴ',
          });
        }}
      >
        <AspectRatio
          cursor="pointer"
          ratio={3.2}
          width={{
            base: '58px',
            md: '58px',
            lg: '80px',
          }}
        >
          <Image src={ShizaiLogo} alt="shizaiのロゴ" layout="fill" />
        </AspectRatio>
      </Box>
    </Link>
  );
};

export default DefaultHeaderLogo;
