const Modal = {
  pars: ['overlay', 'dialog', 'body'],
  baseStyle: {
    overlay: {
      bg: 'textGray.60',
    },
    dialog: {
      marginTop: '5.75rem',
      borderRadius: '16px',
      maxWidth: '64rem',
      maxHeight: '49rem',
    },
    body: {
      padding: 0,
    },
  },
  sizes: {
    sm: {
      dialog: {
        maxW: '64rem',
        maxH: '49rem',
      },
    },
    md: {
      dialog: {
        maxW: '37rem',
        maxH: '43.625rem',
      },
    },
    lg: {
      dialog: {
        maxW: '64rem',
        maxH: '49rem',
      },
    },
  },
  defaultProps: {
    size: 'md',
  },
};

export default Modal;
