const colors = {
  transparent: 'rgba(0, 0, 0, 0)',
  brand: {
    gradient: 'linear-gradient(180deg, #FAF1EF 0%, #EFF2FA 100%)',
  },
  primary: {
    50: 'rgba(248, 249, 253, 1)',
    100: 'rgba(233, 237, 250, 1)',
    200: 'rgba(239, 242, 250, 1)', // primaryLight3
    300: 'rgba(198, 206, 240, 1)', // primaryLight2
    400: 'rgba(149, 166, 228, 1)', // primaryLight1
    500: 'rgba(120, 144, 221, 1)', // primaryDefault
    600: 'rgba(100, 129, 216, 1)', // primaryDark1
    700: 'rgba(76, 111, 210, 1)', // primaryDark2
    800: 'rgba(46,82,183, 1)',
    900: 'rgba(35,64,144, 1)',
  },
  secondary: {
    50: 'rgba(251, 250, 245, 1)',
    100: 'rgba(250,244,240,1)',
    200: 'rgba(250, 241, 239, 1)', // secondaryLight3
    300: 'rgba(251, 201, 190, 1)', // secondaryLight2
    400: 'rgba(248, 150, 129, 1)', // secondaryLight1
    500: 'rgba(247, 131, 108, 1)', // secondaryDefault
    600: 'rgba(246, 112, 85, 1)', // secondaryDark1
    700: 'rgba(246, 103, 77, 1)', // secondaryDark2
    800: 'rgba(243, 69, 38, 1)',
    900: 'rgba(217, 44, 12, 1)',
  },
  alert: {
    50: 'rgba(254, 245, 254, 1)',
    100: 'rgba(254, 240, 246, 1)',
    200: 'rgba(254, 235, 239, 1)', // alertLight3
    300: 'rgba(237, 152, 160, 1)', // alertLight2
    400: 'rgba(245, 56, 69, 1)', // alertLight1
    500: 'rgba(229, 46, 67, 1)', // alertDefault
    600: 'rgba(211, 36, 61, 1)', // alertDark1
    700: 'rgba(198, 28, 53, 1)', // alertDark2
    800: 'rgba(157, 20, 42, 1)',
    900: 'rgba(113, 13, 29, 1)',
  },
  white: {
    // not color schema
    100: 'rgba(255, 255, 255, 1)',
    87: 'rgba(255, 255, 255, 0.87)',
    60: 'rgba(255, 255, 255, 0.6)',
    12: 'rgba(255, 255, 255, 0.12)',
  },
  textGray: {
    // not color schema
    87: 'rgba(15, 20, 36, 0.87)',
    60: 'rgba(15, 20, 36, 0.6)',
    38: 'rgba(15, 20, 36, 0.38)',
    12: 'rgba(15, 20, 36, 0.12)',
    4: 'rgba(15, 20, 36, 0.04)',
  },
};
export default colors;
