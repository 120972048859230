import React, { FC, ReactNode, useEffect } from 'react';
import PlainLayout from 'layouts/Layout/PlainLayout';
import DefaultLayout from 'layouts/Layout/DefaultLayout';
import { useRouter } from 'next/router';
import { layouts } from 'constants/layouts';
import { useDispatch } from 'react-redux';
import { setSession } from 'ducks/session';
import ErrorLayout from './ErrorLayout';
import ContactLayout from './ContactLayout';
import ContentFormLayout from './ContentForm';

interface Props {
  layout: string;
  children?: ReactNode;
  currentUser?: any;
  emailVerified?: boolean;
  idToken?: string;
  userId?: number;
}

const Layout: FC<Props> = ({ layout, children, currentUser, emailVerified, idToken, userId }) => {
  const router = useRouter();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setSession({
        currentUser,
        emailVerified: currentUser?.email_verified || emailVerified,
        idToken,
        userId,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailVerified, idToken, userId, currentUser?.user_id, currentUser?.email_verified]);

  // NOTE: ページ遷移時にスクロール位置を保持してしまうためTOPに戻す
  useEffect(() => {
    typeof window !== 'undefined' && window.scrollTo(0, 0);
  }, [router.asPath]);

  const showLayout = () => {
    switch (layout) {
      case layouts.default:
        return <DefaultLayout>{children}</DefaultLayout>;
      case layouts.plain:
        return <PlainLayout>{children}</PlainLayout>;
      case layouts.contact:
        return <ContactLayout>{children}</ContactLayout>;
      case layouts.contentForm:
        return <ContentFormLayout>{children}</ContentFormLayout>;
      default:
        return <ErrorLayout>{children}</ErrorLayout>;
    }
  };

  return <>{showLayout()}</>;
};

export default Layout;
