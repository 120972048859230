import React, { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { AppContext, AppProps } from 'next/app';
import Head from 'next/head';
import Layout from 'layouts/Layout';
import { ChakraProvider } from '@chakra-ui/react';
import ContextProvider from 'components/ContextProvider';
import { useRouter } from 'next/router';
import * as gtag from 'libs/gtag';
import { Provider } from 'react-redux';
import store from 'ducks/createStore';
import theme from 'theme/theme';
import { GTM_ID } from 'libs/gtag';

if (process.env.NODE_ENV === 'development') {
  if (typeof window !== 'undefined') {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const whyDidYouRender = require('@welldone-software/why-did-you-render');
    whyDidYouRender(React, {
      trackAllPureComponents: true,
      exclude: [/Track/, /InnerSlider/], // disable react-slick
    });
  }
}

const App = ({ Component, pageProps }: AppProps) => {
  const router = useRouter();

  useEffect(() => {
    TagManager.initialize({ gtmId: GTM_ID });
  }, []);

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      gtag.pageview(url);
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);
  return (
    <>
      <Head>
        {/* NOTE: _document.tsx に書くとwarningが起きる */}
        {/* REF: https://github.com/vercel/next.js/issues/6919 */}
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        {/* TODO: Next.jsでWeb Fontの最適化がworkしていないようなので、以下が解決されたらコメントを外す */}
        {/* REF: https://github.com/vercel/next.js/issues/24781 */}
        {/* <style data-href="https://fonts.googleapis.com/css?family=Noto+Sans+JP:wght@400;500;700&subset=japanese&display=swap">
          @font-face {"font-family: 'Noto Sans JP', sans-serif"}
        </style> */}
      </Head>
      <ChakraProvider theme={theme}>
        <Provider store={store}>
          <ContextProvider>
            <Layout {...pageProps}>
              <Component {...pageProps} />
            </Layout>
          </ContextProvider>
        </Provider>
      </ChakraProvider>
    </>
  );
};

App.getInitialProps = async ({ Component, ctx }: AppContext) => {
  const pageProps: any = Component.getInitialProps ? await Component.getInitialProps(ctx) : {};

  return {
    pageProps: {
      ...pageProps,
      referrer: ctx.req?.headers.referer,
    },
  };
};

export default App;
