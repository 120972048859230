import { ThemeComponentProps } from '@chakra-ui/theme';

const Select = {
  variants: {
    'text-icon': (props: ThemeComponentProps) => {
      const { value } = props;
      let color = 'textGray.38';

      if (value) {
        color = 'textGray.87';
      }
      return {
        field: {
          height: '3rem',
          fontSize: '0.875rem',
          textAlign: 'left',
          color,
          borderColor: 'textGray.12',
          backgroundColor: 'transparent',
          borderStyle: 'solid',
          borderWidth: '1px',
          _focus: {
            backgroundColor: `${props.colorScheme}.200`,
          },
          _disabled: {
            opacity: 1.0,
            pointerEvents: 'none',
            cursor: 'not-allowed',
          },
        },
        icon: {
          opacity: 1.0,
          width: '2rem',
        },
      };
    },
  },
};

export default Select;
