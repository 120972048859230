import { FC } from 'react';
import Link from 'next/link';
import ShizaiLogo from 'public/images/shizai_white_logo.svg';
import { Box, Flex, Img, Stack, LinkBox, LinkOverlay, Text } from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { LogCategories, sendClickLog } from 'libs/gtag';
import { paths } from 'constants/paths';

const sendLog = (label: string) => {
  sendClickLog({
    category: LogCategories.FOOTER,
    label,
  });
};

const links = [
  {
    label: '会社概要',
    href: 'https://company.shiza1.com/',
  },
  {
    label: '採用情報',
    href: 'https://careers.shiza1.com/',
  },
  {
    label: 'プライバシーポリシー',
    href: 'https://www.notion.so/d0e4eb80a3c341d3b15c636b96003f5b',
  },
  {
    label: 'お問い合わせ',
    href: paths.contact,
    isInternal: true,
  },
];

const DefaultFooter: FC = () => (
  <Flex
    as="footer"
    align="center"
    backgroundColor="textGray.87"
    minHeight="14.5rem"
    padding={{ base: '1.5rem 0', md: '1.5rem 0', lg: 'initial' }}
  >
    <Box w="100%" maxWidth="64rem" margin="0 auto" padding="0 1.5rem">
      <Flex
        flexDirection={{ base: 'column', md: 'column', lg: 'row' }}
        align="baseline"
        justify="space-between"
      >
        <Box width="100%">
          <Box w={{ base: '4rem', md: '4rem', lg: '5rem' }}>
            <Img src={ShizaiLogo} alt="shizaiの白いロゴ" />
          </Box>
          <Box mt={{ base: '2rem', md: '2rem', lg: '2.1875rem' }} />
          <Box as="nav">
            <Stack
              direction={{ base: 'column', md: 'column', lg: 'row' }}
              as="ul"
              width={{ base: '100%', md: '100%', lg: 'auto' }}
              __css={{
                listStyle: 'none',
              }}
            >
              {links.map((link) => (
                <LinkBox
                  as="li"
                  key={link.label}
                  height={{ base: '3rem', md: '3rem', lg: 'auto' }}
                  pr={{ base: '0', md: '0', lg: '2.2rem' }}
                  onClick={() => {
                    sendLog(link.label);
                  }}
                >
                  <Flex align="center" justify="space-between">
                    {link.isInternal ? (
                      <Link href={link.href} passHref>
                        <LinkOverlay
                          as="span"
                          textStyle="subtitle"
                          color="white.87"
                          fontWeight={{
                            base: 'normal',
                            md: 'normal',
                            lg: 'bold',
                          }}
                        >
                          {link.label}
                        </LinkOverlay>
                      </Link>
                    ) : (
                      <LinkOverlay
                        isExternal
                        href={link.href}
                        textStyle="subtitle"
                        color="white.87"
                        fontWeight={{
                          base: 'normal',
                          md: 'normal',
                          lg: 'bold',
                        }}
                      >
                        {link.label}
                      </LinkOverlay>
                    )}
                    <ChevronRightIcon
                      width="14px"
                      height="14px"
                      color="white"
                      display={{
                        base: 'flex',
                        md: 'flex',
                        lg: 'none',
                      }}
                    />
                  </Flex>
                </LinkBox>
              ))}
            </Stack>
          </Box>
        </Box>
        <Flex
          justify={{ base: 'center', md: 'center', lg: 'flex-end' }}
          width={{ base: '100%', md: '100%', lg: '10rem' }}
        >
          <Text
            as="small"
            color="white.60"
            textAlign="end"
            fontSize={{ base: '0.625rem', md: '0.625rem', lg: '0.85rem' }}
          >
            ©︎ 2022 shizai.inc
          </Text>
        </Flex>
      </Flex>
    </Box>
  </Flex>
);

export default DefaultFooter;
