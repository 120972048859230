import { FC, ReactNode } from 'react';
import DefaultFooter from 'layouts/DefaultFooter';
import { paths } from 'constants/paths';
import { useRouter } from 'next/router';
import { Toast } from 'components/Toast';
import { Box } from '@chakra-ui/react';
import { HubspotProvider } from '@aaronhayes/react-use-hubspot-form';
import QuotationDetailHeader from 'layouts/ContentFormHeader';

type Props = {
  children?: ReactNode;
};

const ContentFormLayout: FC<Props> = ({ children }) => {
  const router = useRouter();

  return (
    <HubspotProvider>
      <Box as="main" position="relative" minHeight="100vh">
        <QuotationDetailHeader />
        <Box
          as="article"
          width="100%"
          maxWidth="64rem"
          margin="0 auto"
          minHeight={router.pathname === paths.orderComplete ? 'initial' : '100vh'}
        >
          {children}
        </Box>
        <Toast />
        <DefaultFooter />
      </Box>
    </HubspotProvider>
  );
};

export default ContentFormLayout;
