const Checkbox = {
  parts: ['control'],
  defaultProps: {
    colorScheme: 'primary',
    size: 'md',
  },
  baseStyle: {
    control: {
      borderRadius: '3px',
      borderColor: '#0F1424',
      _focus: {
        boxShadow: 'unset',
      },
    },
  },
  sizes: {
    md: {
      control: {
        width: '20px',
        height: '20px',
      },
    },
  },
};

export default Checkbox;
