import React from 'react';
import DefaultHeaderLogo from 'layouts/DefaultHeaderLogo';
import {
  AspectRatio,
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  useDisclosure,
} from '@chakra-ui/react';

import { HamburgerIcon } from '@chakra-ui/icons';
import { paths } from 'constants/paths';
import Link from 'next/link';
import { LogCategories, sendClickLog } from 'libs/gtag';
import ShizaiLogo from 'public/images/shizai_logo.svg';
import Image from 'next/legacy/image';
import NavigationMenu from './NavigationMenu';

const DefaultHeader = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef<HTMLButtonElement>(null);

  return (
    <>
      <Box
        as="header"
        display="flex"
        alignItems="center"
        position="sticky"
        top={0}
        height={{ base: '3.5rem', md: '3.5rem', lg: '4.5rem' }}
        padding={{ base: '0 0 0 1.5rem', lg: '0 1.5rem' }}
        backgroundColor="white.100"
        zIndex={2}
      >
        <Flex align="center" justify="space-between" width="100%" maxWidth="64rem" margin="0 auto">
          <DefaultHeaderLogo />
          <Box display={{ base: 'none', lg: 'block' }} width="100%">
            <NavigationMenu />
          </Box>
          <Box
            display={{ base: 'block', lg: 'none' }}
            position="relative"
            width="4.5rem"
            height="3.5rem"
          >
            <Button
              variant="icon"
              size="xs"
              ref={btnRef}
              onClick={onOpen}
              p="0"
              width="4.5rem"
              height="3.5rem"
            >
              <HamburgerIcon w={18} h={14} />
            </Button>
          </Box>
        </Flex>
        <Drawer
          isOpen={isOpen}
          onClose={onClose}
          placement="right"
          finalFocusRef={btnRef}
          size="full"
        >
          <DrawerOverlay>
            <DrawerContent>
              <DrawerCloseButton right="1.3rem" />
              <DrawerHeader>
                <Link href={paths.root} passHref>
                  <Box
                    display="flex"
                    alignItems="center"
                    onClick={() => {
                      sendClickLog({
                        category: LogCategories.DRAWER,
                        label: 'shizaiのロゴ',
                      });
                      if (onClose) onClose();
                    }}
                  >
                    <AspectRatio
                      cursor="pointer"
                      ratio={3.2}
                      width={{
                        base: '58px',
                        md: '58px',
                        lg: '80px',
                      }}
                    >
                      <Image src={ShizaiLogo} alt="shizaiのロゴ" layout="fill" />
                    </AspectRatio>
                  </Box>
                </Link>
              </DrawerHeader>
              <DrawerBody py="1.5rem">
                <NavigationMenu closeCallback={onClose} />
              </DrawerBody>
            </DrawerContent>
          </DrawerOverlay>
        </Drawer>
      </Box>
    </>
  );
};

export default DefaultHeader;
