export const paths = {
  root: '/',
  account: '/account',
  accountHistory: '/account/history',
  // NOTE: UUIDがunknownのため一旦anyにしている
  accountHistoryDetail: (orderAccessableUuid: any): string =>
    `/account/history/${orderAccessableUuid}`,
  accountHistoryOrderId: '/account/history/[order_id]',
  contact: '/contact',
  contactPackage: '/contact/package',
  contactOther: '/contact/other',
  contactServiceDocument: '/contact/service_document',
  inquiryServiceDocument: '/inquiry/service_document',
  contactComplete: '/contact/complete',
  inquiryDetail: (slug: string) => `/inquiry/${slug}`,
  orderComplete: '/order/complete',
  orderConfirm: '/order/confirm',
  orderDesign: '/order/design',
  orderShipping: '/order/shipping',
  package: '/package',
  packageDetail: ({ packageFriendlyId }: { packageFriendlyId: string | null }): string =>
    packageFriendlyId ? `/package/${packageFriendlyId}` : '/packages',
  packageContactExpert: '/package/contact_expert',
  packageUrl: '/package/[package_url]',
  passwordReset: '/password/reset',
  passwordResetConfirm: '/password/reset/confirm',
  passwordResetExpired: '/password/reset/expired',
  passwordResetRequest: '/password/reset/request',
  passwordResetRequestConfirm: '/password/reset/request/confirm',
  quotation: '/quotation',
  quotationDetail: (packageUrl: string | null) => `/quotation/${packageUrl}`,
  quotationContact: '/quotation/contact',
  quotationComplete: '/quotation/complete',
  signin: '/login',
  signinConfirm: '/signin/confirm',
  signup: '/signup',
  signupConfirm: '/signup/confirm',
  blog: '/blog',
  blogDetail: ({ blogFriendlyId }: { blogFriendlyId: string }) => `/blog/${blogFriendlyId}`,
};

export const apiPaths = {
  getIdTokenInSessionCookie: '/api/get_id_token_in_session_cookie',
  getUserEmailVerifiedStatus: '/api/get_user_email_verified_status',
  createCsrfToken: '/api/create_csrf_token',
  createSessionCookieFromIdToken: '/api/create_session_cookie_from_id_token',
  createUser: '/api/create_user',
  checkAllowedFileForOrderDesign: '/api/check_allowed_file_for_order_design',
  deleteSessionCookie: '/api/delete_session_cookie',
};

export const cloudStoragePaths = {
  orderDesignsDesignDataUserOriginal: 'order_designs/design_data/user_original/',
};

export const privateRoutes = [
  paths.account,
  paths.accountHistory,
  paths.accountHistoryOrderId,
  paths.orderComplete,
  paths.orderConfirm,
  paths.orderDesign,
  paths.orderShipping,
];

export const authenticationRoutes = [paths.signin, paths.signup];
