const Popover = {
  parts: ['content', 'body'],
  baseStyle: {
    body: {
      padding: 0,
    },
    content: {
      boxShadow: 'default',
      bg: 'white',
      borderColor: 'white',
      borderRadius: '16px',
      _focus: {
        borderColor: 'white',
        boxShadow: 'default',
      },
    },
  },
};

export default Popover;
