import DefaultFooter from 'layouts/DefaultFooter';
import React, { FC, ReactNode } from 'react';
import { Box } from '@chakra-ui/react';

type Props = {
  children?: ReactNode;
};

const ErrorLayout: FC<Props> = ({ children }) => (
  <>
    <Box as="main">{children}</Box>
    <DefaultFooter />
  </>
);

export default ErrorLayout;
