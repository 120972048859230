import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ModalState, ModalType, ModalTypes } from './types';

export const initialState: ModalState = {
  modalType: ModalTypes.CLOSE,
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal: (
      state,
      action: PayloadAction<{ modalType: Exclude<ModalType, typeof ModalTypes.CLOSE> }>,
    ) => ({
      ...state,
      modalType: action.payload.modalType,
    }),
    closeModal: (state) => ({
      ...state,
      modalType: ModalTypes.CLOSE,
    }),
  },
});

export default modalSlice;
export const ModalReducer = modalSlice.reducer;
