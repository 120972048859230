const textStyles = {
  hero: {
    fontFamily: 'Mont-Bold',
    fontWeight: 700,
    fontSize: { base: '2.25rem', md: '2.25rem', lg: '4.5rem' },
    lineHeight: { base: '110%', md: '110%', lg: '130%' },
    letterSpacing: '1.2px',
    color: 'textGray.87',
  },
  body1: {
    fontSize: { base: '0.875rem', md: '0.875rem', lg: '1rem' },
    fontWeight: 400,
    lineHeight: '180%',
    letterSpacing: '0.2px',
    color: 'textGray.60',
    // overflow: "visible",
    // textOverflow: "clip",
    // whiteSpace: "normal",
  },
  body2: {
    fontSize: { base: '0.75rem', md: '0.75rem', lg: '0.875rem' },
    fontWeight: 400,
    lineHeight: '180%',
    letterSpacing: '0.2px',
    color: 'textGray.60',
    // overflow: "visible",
    // textOverflow: "clip",
    // whiteSpace: "normal",
  },
  subtitle: {
    fontSize: { base: '0.875rem', md: '0.875rem', lg: '1rem' },
    fontWeight: 700,
    lineHeight: '180%',
    letterSpacing: '0.2px',
    color: 'textGray.87',
    // overflow: "visible",
    // textOverflow: "clip",
    // whiteSpace: "normal",
  },
  caption: {
    fontSize: { base: '0.625rem', md: '0.625rem', lg: '0.75rem' },
    fontWeight: 400,
    lineHeight: '180%',
    letterSpacing: '0.4px',
    color: 'textGray.60',
  },
  h1: {
    fontSize: { base: '1.75rem', md: '1.75rem', lg: '3rem' },
    fontWeight: 500,
    lineHeight: '130%',
    letterSpacing: '-0.5px',
  },
  h2: {
    fontSize: { base: '1.25rem', md: '1.25rem', lg: '2.125rem' },
    fontWeight: 400,
    lineHeight: '150%',
    letterSpacing: '0px',
  },
  h3: {
    fontSize: { base: '1.125rem', md: '1.125rem', lg: '1.5rem' },
    fontWeight: 400,
    lineHeight: '150%',
    letterSpacing: '0.1px',
    color: 'textGray.87',
  },
  h4: {
    fontSize: { base: '1rem', md: '1rem', lg: '1.25rem' },
    fontWeight: 400,
    lineHeight: '150%',
    color: 'textGray.87',
  },
};

export default textStyles;
