import Link from './Link';
import Button from './Button';
import Modal from './Modal';
import Popover from './Popover';
import Checkbox from './Checkbox';
import Select from './Select';

const components = {
  Link,
  Button,
  Modal,
  Popover,
  Checkbox,
  Select,
};

export default components;
