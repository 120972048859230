/* eslint-disable camelcase */
export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_TRACKING_ID || '';
export const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID || '';

export const ActionTypeNames = {
  CLICK: 'custom_click_action',
  HOVER: 'custom_hover_action',
  PACKAGE_INPUT: 'package_input',
  SHIPPING_ADDRESS_FORM_INPUT: 'shipping_address_form_input',
  QUOTATION_ADDRESS_FORM_INPUT: 'quotation_address_form_input',
  SELECT_QUOTATION_PACKAGE: 'select_quotation_package',
  SUBMIT_PACKAGE_ORDER_CONTENT: 'submit_package_order_content',
  QUOTATION_PACKAGE_INPUT: 'quotation_package_input',
} as const;

export const LogCategories = {
  PACKAGE: 'パッケージ',
  POPULAR_PACKAGE: '人気のパッケージ',
  ORDER: 'オーダー',
  HEADER: 'ヘッダー',
  DRAWER: 'ドロワー',
  FOOTER: 'フッター',
  TOP: 'トップ',
  SIGN_IN: 'ログイン',
  SIGN_UP: '登録',
  PASSWORD_RESET: 'パスワードの再設定',
  SIMULATED_CUSTOMER_SERVICE_EXPERIENCE: '疑似接客体験',
  EASY_PURCHASE: 'かんたん購入',
  CONTACT: '問い合わせ',
  CONTACT_EXPORT: '専門家に選定を頼む',
  QUOTE_FROM_LIST: '一覧から見積もり',
  DOWNLOAD_DOCUMENT: '書類のダウンロード',
  NOT_FOUND: 'not_found',
  SERVER_ERROR: 'server_error',
} as const;

type LogCategoryTypes = typeof LogCategories[keyof typeof LogCategories];

type ActionTypes = typeof ActionTypeNames[keyof typeof ActionTypeNames];

type FieldObject = Record<string, string | number | undefined | null>;

export interface BaseParams extends FieldObject {
  category: LogCategoryTypes;
  label: string;
  value?: string | number | null;
}

export interface PackageInputParams extends BaseParams {
  package_id: number;
  package_name: string;
  input_id: number;
  input_key: string;
  input_value: string | number;
  is_selected?: -1 | 1; // -1: remove, 1: select
}

export interface PackageSubmitParams extends BaseParams {
  package_id: number;
  package_name: string;
  size_id: number;
  size_name: string;
  order_quantity_id: number;
  order_quantity: number;
  material_or_color_id: number;
  material_or_color_name: string;
  machinings: string; // JSON.stringify Array<{id: number, name: string}>
  is_both_sides_check: string;
  printing_surfaces_number: number;
  printing_colors_number: number;
}

export interface ShippingAddressFormInputParams extends BaseParams {
  phone_number: 0 | 1;
  zip_code: 0 | 1;
  address_1: 0 | 1;
  address_2: 0 | 1;
  name_of_person_in_charge: 0 | 1;
  company_name: 0 | 1;
}

export interface SelectQuotationPackageParams extends BaseParams {
  package_id: number;
  package_name: string;
  is_selected: -1 | 1;
}

export interface QuotationPackageInputParams extends BaseParams {
  package_id: number;
  package_name: string;
  input_key: string;
  input_value: string | number;
}

export interface QuotationAddressInputParams extends BaseParams {
  email: 0 | 1;
  phone_number: 0 | 1;
  company_name: 0 | 1;
  name: 0 | 1;
  request: 0 | 1;
}

interface ClickActionProps {
  action: typeof ActionTypeNames.CLICK;
  params: BaseParams;
}

interface HoverActionProps {
  action: typeof ActionTypeNames.HOVER;
  params: BaseParams;
}

interface PackageInputActionProps {
  action: typeof ActionTypeNames.PACKAGE_INPUT;
  params: PackageInputParams;
}

interface PackageSubmitProps {
  action: typeof ActionTypeNames.SUBMIT_PACKAGE_ORDER_CONTENT;
  params: PackageSubmitParams;
}

interface ShippingAddressFormInputProps {
  action: typeof ActionTypeNames.SHIPPING_ADDRESS_FORM_INPUT;
  params: ShippingAddressFormInputParams;
}

interface SelectQuotationPackageProps {
  action: typeof ActionTypeNames.SELECT_QUOTATION_PACKAGE;
  params: SelectQuotationPackageParams;
}

interface QuotationPackageInputProps {
  action: typeof ActionTypeNames.QUOTATION_PACKAGE_INPUT;
  params: QuotationPackageInputParams;
}

interface QuotationAddressInputProps {
  action: typeof ActionTypeNames.QUOTATION_ADDRESS_FORM_INPUT;
  params: QuotationAddressInputParams;
}

type LogPropsType =
  | ClickActionProps
  | HoverActionProps
  | PackageInputActionProps
  | PackageSubmitProps
  | ShippingAddressFormInputProps
  | SelectQuotationPackageProps
  | QuotationPackageInputProps
  | QuotationAddressInputProps;

const sendLog = (payload: LogPropsType): void => {
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  event(payload);
};

export const sendPackageInputLog = (params: PackageInputParams): void => {
  sendLog({
    action: ActionTypeNames.PACKAGE_INPUT,
    params,
  });
};

export const sendClickLog = (params: BaseParams) => {
  sendLog({
    action: ActionTypeNames.CLICK,
    params,
  });
};

export const sendHoverLog = (params: BaseParams) => {
  sendLog({
    action: ActionTypeNames.HOVER,
    params,
  });
};

export const sendPackageOrderContentSubmitLog = (params: PackageSubmitParams) => {
  sendLog({
    action: ActionTypeNames.SUBMIT_PACKAGE_ORDER_CONTENT,
    params,
  });
};

export const sendShippingAddressFormLog = (params: ShippingAddressFormInputParams) => {
  sendLog({
    action: ActionTypeNames.SHIPPING_ADDRESS_FORM_INPUT,
    params,
  });
};

export const sendSelectQuotationPackageLog = (params: SelectQuotationPackageParams) => {
  sendLog({
    action: ActionTypeNames.SELECT_QUOTATION_PACKAGE,
    params,
  });
};

export const sendQuotationPackageInputLog = (params: QuotationPackageInputParams) => {
  sendLog({
    action: ActionTypeNames.QUOTATION_PACKAGE_INPUT,
    params,
  });
};

export const sendQuotationAddressInputLog = (params: QuotationAddressInputParams) => {
  sendLog({
    action: ActionTypeNames.QUOTATION_ADDRESS_FORM_INPUT,
    params,
  });
};

export const pageview = (url: string): void => {
  interface PageEventProps {
    event: string;
    page: string;
  }

  const pageEvent: PageEventProps = {
    event: 'pageview',
    page: url,
  };
  window && window?.dataLayer && window?.dataLayer.push(pageEvent);
};

type GaEventProps = {
  action: ActionTypes;
  params?: FieldObject;
};

const event = ({ action, params }: GaEventProps): void => {
  params ? window.gtag('event', action, params) : window.gtag('event', action);
};
