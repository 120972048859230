import { FC, ReactNode } from 'react';
import DefaultHeader from 'layouts/DefaultHeader';
import DefaultFooter from 'layouts/DefaultFooter';
import { toastMessages } from 'constants/toast';
import { destroyCookie, parseCookies } from 'nookies';
import { paths } from 'constants/paths';
import { useRouter } from 'next/router';
import { toast, Toast } from 'components/Toast';
import { webStorage } from 'constants/webStorage';
import { cookieName } from 'constants/cookie';
import { Box } from '@chakra-ui/react';

type Props = {
  children?: ReactNode;
};

const DefaultLayout: FC<Props> = ({ children }) => {
  const router = useRouter();
  // NOTE: webstorageはString型しか扱わない
  // REF: https://developer.mozilla.org/ja/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#basic_concepts
  if (
    typeof window !== 'undefined' &&
    window.sessionStorage.getItem(webStorage.reloadAfterLoginNotification) === 'true'
  ) {
    toast(toastMessages.loggedIn);
    window.sessionStorage.reloadAfterLoginNotification = false;
  }

  if (
    typeof window !== 'undefined' &&
    window.sessionStorage.getItem(webStorage.reloadAfterLogoutNotification) === 'true'
  ) {
    toast(toastMessages.logout);
    window.sessionStorage.reloadAfterLogoutNotification = false;
  }

  const cookies = parseCookies();

  if (typeof window !== 'undefined' && cookies.reloadAfterSignupNotification === 'true') {
    toast(toastMessages.signedUp);
    destroyCookie(null, cookieName.reloadAfterSignupNotification, { path: paths.root });
  }

  return (
    <Box as="main" position="relative" minHeight="100vh">
      <DefaultHeader />
      <Box
        as="article"
        width="100%"
        maxWidth="64rem"
        margin="0 auto"
        minHeight={router.pathname === paths.orderComplete ? 'initial' : '100vh'}
      >
        {children}
      </Box>
      <Toast />
      <DefaultFooter />
    </Box>
  );
};

export default DefaultLayout;
